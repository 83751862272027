.section {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.imageContainer {
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  margin: auto;
  max-width: 600px;
  position: relative;
  width: 100%;
}

.title {
  color: var(--color-primary-500);
  margin-bottom: var(--gap-huge);
  margin-top: var(--gap);
}
